import React from 'react';
import { useTranslation } from 'react-i18next';
import { GLOVO_ROLES } from 'const';
import clsx from 'helpers/clsx';
import { getCountry } from 'helpers/jwt';
import { useUser } from 'hooks/useUser';
import { CountryCodeEnum } from 'interfaces';
import { RoleEnum } from 'interfaces/users';

import { Checkbox } from 'components/FormControl';

import { IProps } from './types';

import classes from './Roles.module.scss';

const Roles: React.FC<IProps> = ({ className, checkboxClassName, roles, onChangeRole, isFilter, canHasGlovoRoles }) => {
	const { t } = useTranslation('roles');
	const { getRolesForEdit, hasOnlyChosenRole } = useUser();
	const countryCode = getCountry();

	const handleChangeRole = (role: RoleEnum) => () => onChangeRole(role);

	const renderRoles = getRolesForEdit()?.filter((role: RoleEnum) => {
		if (role === RoleEnum.CUSTOMER && countryCode !== CountryCodeEnum.UA) return false;
		if (role === RoleEnum.CREDENTIALS_ADMIN) return false;
		if (role === RoleEnum.MENUTOOL) return false;
		if (role === RoleEnum.ACCOUNT && hasOnlyChosenRole(RoleEnum.ACCOUNT)) return false;
		if (role !== RoleEnum.STORE_ADMIN) return true;
		return isFilter;
	});

	return (
		<div className={clsx(classes.flex, className)}>
			{renderRoles &&
				renderRoles.map((role: RoleEnum) => (
					<Checkbox
						isChecked={roles?.includes(role)}
						onChange={handleChangeRole(role)}
						rootClassName={checkboxClassName}
						disabled={canHasGlovoRoles ? false : !isFilter && GLOVO_ROLES?.includes(role)}
						key={role}
					>
						{t(`roles:${role}`)}
					</Checkbox>
				))}
		</div>
	);
};

export default Roles;
