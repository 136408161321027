import React from 'react';
import { useTranslation } from 'react-i18next';
import { EXPORT_BUTTON_STEP_ID } from 'const';
import GetStartedProvider from 'containers/GetStarted/Provider';
import { useGetStartedContext } from 'contexts/GetStarted';
import clsx from 'helpers/clsx';
import { getCountry } from 'helpers/jwt';
import { useExport } from 'hooks/useExport';
import { useOrders } from 'hooks/useOrders';
import { useUser } from 'hooks/useUser';
import { CountryCodeEnum } from 'interfaces';
import { ReactComponent as IconExport } from 'static/images/export.svg';
import { ReactComponent as FileExport } from 'static/images/fileExport.svg';
import { TEST_IDS } from 'tests/config';

import ExportButton from 'components/ExportButton';
import { Select } from 'components/FormControl';
import Loader from 'components/Loader';
import Modal from 'components/modals/Modal';

import { IProps } from './types';

import classes from './ExportsOrders.module.scss';

const ExportsOrders: React.FC<IProps> = ({ rootClassName, buttonClassName, isRow }) => {
	const {
		exportSummary,
		exportUAOrders,
		exportExcel,
		exportPromoDelivery,
		exportPromoProducts,
		exportNavision,
		onload: onloadExport,
		exportSummaryDH,
		exportTapOnPhone,
		openModal,
		setOpenModal,
		exportGMOReport,
		exportManualAdjustments,
		exportDisputes,
		exportDisputesSummary,
	} = useExport();
	const { isAdmin, isFinance, user, isPartner, isAccount, isCredentialsAdmin } = useUser();
	const { items, filter } = useOrders();
	const { t } = useTranslation(['exports']);
	const countryCode = getCountry();
	const { currentStep } = useGetStartedContext();

	const closeModal = () => setOpenModal(false);

	const exportDropDown = {
		navision: exportNavision,
		summaryDH: exportSummaryDH,
		tapOnPhone: exportTapOnPhone,
		disputesAll: exportDisputes,
		disputesSummary: exportDisputesSummary,
	};

	if (!items.length)
		return (
			<GetStartedProvider sort={16} id={EXPORT_BUTTON_STEP_ID} position="right" canPush={false}>
				<div
					data-testid={TEST_IDS.ORDERS_EXPORTS_ROOT}
					className={clsx(classes.exports, rootClassName, {
						[classes.row]: isRow,
					})}
				/>
			</GetStartedProvider>
		);

	const commonExports = [
		{ value: 'navision', label: t('exports:navision'), Icon: IconExport },
		{ value: 'summaryDH', label: t('exports:summaryDH'), Icon: IconExport },
	];

	const exportOptions: Partial<Record<CountryCodeEnum, { value: string; label: string; Icon: typeof IconExport }[]>> = {
		[CountryCodeEnum.UA]: [...commonExports, { value: 'tapOnPhone', label: t('exports:tapOnPhone'), Icon: IconExport }],
		[CountryCodeEnum.KZ]: [
			...commonExports,
			{ value: 'disputesAll', label: t('exports:disputesAll'), Icon: IconExport },
			{ value: 'disputesSummary', label: t('exports:disputesSummary'), Icon: IconExport },
		],
	};

	const options = countryCode ? exportOptions[countryCode] || commonExports : [];
	const isAccountManagerOnly = isAccount && !isAdmin && !isFinance;
	const canExportDisputesAndSummary = (isPartner || isAccountManagerOnly) && !isCredentialsAdmin;
	const canExportSummaryOnly = (isAdmin || isFinance) && !filter?.disputesTab;

	return (
		<>
			{openModal && (
				<Modal
					open
					onClose={closeModal}
					buttons={{
						confirm: {
							text: t('largeExportButton'),
							onClick: closeModal,
						},
					}}
					rootClassName={classes.modal}
				>
					<React.Fragment>
						<div className={classes.modal_icon}>
							<FileExport />
						</div>
						<h3>{t('largeExportTitle')}</h3>
						<div
							className={classes.modal_body}
							dangerouslySetInnerHTML={{
								__html: t('largeExportDescription', {
									email: user?.email,
								}),
							}}
						/>
					</React.Fragment>
				</Modal>
			)}
			{onloadExport && <Loader />}
			<div
				className={clsx(classes.exports, rootClassName, {
					[classes.row]: isRow,
				})}
				data-testid={TEST_IDS.ORDERS_EXPORTS_ROOT}
			>
				<div>
					<GetStartedProvider sort={16} id={EXPORT_BUTTON_STEP_ID} position="bottom" canPush={!!items.length}>
						<ExportButton
							onClick={exportExcel}
							className={clsx(classes.export, buttonClassName, {
								[classes.getStarted]: currentStep?.id === EXPORT_BUTTON_STEP_ID,
							})}
						>
							{t('exports:amendments')}
						</ExportButton>
					</GetStartedProvider>
					<ExportButton onClick={exportPromoDelivery} className={clsx(classes.export, buttonClassName)}>
						{t('exports:deliveryDiscountAssumedByPartner')}
					</ExportButton>
					<ExportButton onClick={exportPromoProducts} className={clsx(classes.export, buttonClassName)}>
						{t('exports:productDiscountAssumedByPartner')}
					</ExportButton>
					{canExportDisputesAndSummary && (
						<>
							{countryCode && [CountryCodeEnum.KZ].includes(countryCode) && (
								<>
									<ExportButton onClick={exportDisputes} className={clsx(classes.export, buttonClassName)}>
										{t('exports:disputesAll')}
									</ExportButton>
									<ExportButton onClick={exportDisputesSummary} className={clsx(classes.export, buttonClassName)}>
										{t('exports:disputesSummary')}
									</ExportButton>
								</>
							)}
						</>
					)}
					{canExportSummaryOnly && (
						<ExportButton onClick={exportSummary} className={clsx(classes.export, buttonClassName)}>
							{t('exports:summary')}
						</ExportButton>
					)}
					{!isAccountManagerOnly && isPartner && countryCode && [CountryCodeEnum.UA].includes(countryCode) && (
						<>
							<ExportButton onClick={exportSummary} className={clsx(classes.export, buttonClassName)}>
								{t('exports:summary')}
							</ExportButton>
							<ExportButton onClick={exportGMOReport} className={clsx(classes.export, buttonClassName)}>
								{t('exports:GMO')}
							</ExportButton>
						</>
					)}
				</div>
				<div>
					{isAccountManagerOnly && countryCode && [CountryCodeEnum.UA].includes(countryCode) && (
						<>
							<ExportButton onClick={exportGMOReport} className={clsx(classes.export, buttonClassName)}>
								{t('exports:GMO')}
							</ExportButton>
							<ExportButton onClick={exportSummary} className={clsx(classes.export, buttonClassName)}>
								{t('exports:summary')}
							</ExportButton>
						</>
					)}
					{(isAdmin || isFinance) && (
						<>
							<ExportButton onClick={exportUAOrders} className={clsx(classes.export, buttonClassName)}>
								{t('exports:orders', { countryCode })}
							</ExportButton>
							{countryCode && [CountryCodeEnum.KZ, CountryCodeEnum.UA, CountryCodeEnum.KG].includes(countryCode) && (
								<ExportButton onClick={exportGMOReport} className={clsx(classes.export, buttonClassName)}>
									{t('exports:GMO')}
								</ExportButton>
							)}
							<ExportButton onClick={exportManualAdjustments} className={clsx(classes.export, buttonClassName)}>
								{t('exports:manualAdjustments')}
							</ExportButton>
							<Select
								withoutClear
								isExport
								defaultValue={t('exports:showMore')}
								onChange={(v) => exportDropDown[v as keyof typeof exportDropDown]()}
								controlClassName={classes.exportDropDown}
								rootClassName={classes.exportDropDownRoot}
								options={options}
							/>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default ExportsOrders;
