import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_STORE_ADRESS_HISTORY } from 'api/storeAddresses';
import CreateEditStoreAddress from 'containers/modals/CreateEditStoreAddress';
import NotificationAuthOtherUser from 'containers/NotificationAuthOtherUser';
import StoreListInfo from 'containers/StoreListInfo';
import clsx from 'helpers/clsx';
import { STORE_TYPE } from 'helpers/storeAddresses';
import { useExport } from 'hooks/useExport';
import { useStoreAddresses } from 'hooks/useStoreAddresses';
import { useUser } from 'hooks/useUser';
import { IStoreAddress } from 'interfaces/storeAddress';
import { ReactComponent as NewIcon } from 'static/images/circle_arrows.svg';
import { ReactComponent as CloseIcon } from 'static/images/close.svg';
import { ReactComponent as IconExport } from 'static/images/export.svg';
import { ReactComponent as Empty } from 'static/images/fielder.svg';
import noSelectedAddressSrc from 'static/images/no-selected-address.svg';
import { ReactComponent as PlaceIcon } from 'static/images/place.svg';
import { ReactComponent as SearchIcon } from 'static/images/search.svg';
import { ReactComponent as TemporaryIcon } from 'static/images/temporary.svg';
import { TEST_IDS } from 'tests/config';

import CategoryGen from 'components/CategoryGen';
import ExportButton from 'components/ExportButton';
import { Checkbox, Input, Select } from 'components/FormControl';
import IconButton from 'components/IconButton';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';
import Tooltip from 'components/Tooltip';

import classes from './StoreAddresses.module.scss';

const StoreAddresses: React.FC = () => {
	const { onload, wasLoaded, fetchStoreAddresses, filter, pagionation, items, total, changeFilter, setPage, setAbortRef, aborterRef } =
		useStoreAddresses();
	const { isFinance } = useUser();
	const [selectedStoreAddress, setSelectedStoreAddress] = useState<IStoreAddress>();
	const { t } = useTranslation(['storeAddresses', 'roles', 'form', 'common', 'exports']);
	const { exportStoreAddresses, exportStoreAddressesExtended, onload: onloadExport } = useExport();

	useEffect(fetchStoreAddresses, [fetchStoreAddresses, filter, pagionation]);

	const exportHandlers = {
		'1': exportStoreAddresses,
		'2': exportStoreAddressesExtended,
	};

	const availableExportOptions = [
		{ value: '1', label: t('exports:defaultStoreAddresses'), Icon: IconExport },
		{ value: '2', label: t('exports:extendedStoreAddresses'), Icon: IconExport },
	];

	const handleStoreAddressExport = (option: string) => {
		const exportHandler = exportHandlers[option as keyof typeof exportHandlers];

		if (!exportHandler) return;

		exportHandler();
	};

	const handleChangeAddress = (value: string) => {
		if (aborterRef && typeof setAbortRef === 'function') {
			aborterRef.abort();
			setAbortRef(new AbortController());
		}
		changeFilter('searchQuery', value);
	};

	const start = pagionation.page * pagionation.pageSize + 1;
	const end = start + pagionation.pageSize - 1;

	const handleClickPrev = () => {
		setPage(pagionation.page - 1);
	};

	const handleClickNext = () => {
		setPage(pagionation.page + 1);
	};

	const renderPagination = () => {
		if (!items.length) return null;
		return (
			<Pagination start={start} end={end} total={total} onClickNext={handleClickNext} onClickPrev={handleClickPrev} className={classes.pagination} />
		);
	};

	const handleChangeCheckbox = (storeType: STORE_TYPE) => () => {
		changeFilter('status', storeType);
	};

	const handleCloseSelectedStoreAddress = () => setSelectedStoreAddress(undefined);

	useEffect(() => {
		if (selectedStoreAddress) {
			const it = items.find(({ storeAddressId }) => storeAddressId === selectedStoreAddress.storeAddressId);
			setSelectedStoreAddress(it);
		}
	}, [items, selectedStoreAddress]);

	const handleSelectStoreAddress = (idStoreAddress: IStoreAddress['storeAddressId']) => () => {
		if (idStoreAddress === selectedStoreAddress?.storeAddressId) {
			return setSelectedStoreAddress(undefined);
		}
		const it = items.find(({ storeAddressId }) => storeAddressId === idStoreAddress);
		setSelectedStoreAddress(it);
	};

	const getStoreTypeIcon = (type: STORE_TYPE) => {
		switch (type) {
			case STORE_TYPE.TEMPORARY:
				return (
					<Tooltip text={t('temp')} outside className={classes.tooltipTemporary} warning>
						<TemporaryIcon className={clsx(classes.placeIcon, classes.placeIcon_temp)} />
					</Tooltip>
				);
			case STORE_TYPE.APPROVED:
				return <PlaceIcon className={classes.placeIcon} />;
			case STORE_TYPE.NEW:
				return <NewIcon className={classes.placeIcon} />;
			default:
				return <PlaceIcon className={classes.placeIcon} />;
		}
	};

	return (
		<div className={classes.root} data-testid={TEST_IDS.STORE_ADDRESSES_PAGE}>
			<div className={classes.body}>
				<NotificationAuthOtherUser />
				<div className={classes.title}>{t('title')}</div>
				<div className={clsx(classes.flex, classes.justifyContentBetween, classes.nav)}>
					<Input
						onChange={handleChangeAddress}
						value={filter.searchQuery}
						placeholder={t('search')}
						rootClassName={classes.inputRoot}
						controlClassName={classes.input}
						Icon={SearchIcon}
						positionIcon="right"
						delayOnChange={500}
					/>
					<CreateEditStoreAddress />
				</div>
				<div className={clsx(classes.flex, classes.filters)}>
					<Checkbox
						isChecked={filter.status.includes(STORE_TYPE.NEW)}
						onChange={handleChangeCheckbox(STORE_TYPE.NEW)}
						rootClassName={classes.checkbox}
					>
						{t('fields.new')}
					</Checkbox>
					<Checkbox
						isChecked={filter.status.includes(STORE_TYPE.TEMPORARY)}
						onChange={handleChangeCheckbox(STORE_TYPE.TEMPORARY)}
						rootClassName={classes.checkbox}
					>
						{t('fields.temporary')}
					</Checkbox>
					<Checkbox
						isChecked={filter.status.includes(STORE_TYPE.APPROVED)}
						onChange={handleChangeCheckbox(STORE_TYPE.APPROVED)}
						rootClassName={classes.checkbox}
					>
						{t('fields.permanent')}
					</Checkbox>
					<Checkbox
						isChecked={filter.status.includes(STORE_TYPE.DEACTIVATED)}
						onChange={handleChangeCheckbox(STORE_TYPE.DEACTIVATED)}
						rootClassName={classes.checkbox}
					>
						{t('fields.deactivated')}
					</Checkbox>
				</div>
				{onload && !wasLoaded ? (
					<Loader simple />
				) : (
					<>
						{(onload || onloadExport) && <Loader />}
						<table className={classes.items}>
							<thead>
								<tr>
									<th>{t('fields.storeName')}</th>
									<th>Id</th>
									<th className={classes.address}>{t('fields.address')}</th>
									<th>
										{items.length > 0 && (
											<>
												{isFinance && (
													<Select
														withoutClear
														isExport
														defaultValue={t('export')}
														onChange={handleStoreAddressExport}
														controlClassName={classes.exportDropDown}
														rootClassName={classes.exportDropDownRoot}
														options={availableExportOptions}
													/>
												)}
												{!isFinance && (
													<ExportButton onClick={exportStoreAddresses} className={classes.export}>
														{t('export')}
													</ExportButton>
												)}
											</>
										)}
									</th>
								</tr>
							</thead>
							<tbody>
								{!items.length ? (
									<tr className={classes.empty}>
										<td colSpan={4}>
											<div className={classes.inner}>
												<Empty />
												<div>{t('emptyFilters')}</div>
												<div>{t('emptyTypes')}</div>
											</div>
										</td>
									</tr>
								) : (
									<>
										{items.map((item) => {
											const row = (
												<>
													<td>
														<div className={clsx(classes.flex, classes.alignItemsCenter)}>
															{getStoreTypeIcon(item.status)}
															{item.storeName}
														</div>
														<div className={classes.blockCategoryGen}>
															{item.gen && <CategoryGen gen={item.gen} />}
															{item.category && <CategoryGen category={item.category} />}
														</div>
													</td>
													<td className={classes.id}>{item.storeAddressId}</td>
													<td colSpan={2} className={classes.address}>
														{item.address}
													</td>
												</>
											);
											return (
												<React.Fragment key={item.id}>
													<tr className={classes.spacer}>
														<td colSpan={3}></td>
													</tr>
													{item.status === STORE_TYPE.TEMPORARY || item.status === STORE_TYPE.NEW ? (
														<CreateEditStoreAddress item={item} beforeClick={handleCloseSelectedStoreAddress}>
															<tr
																className={clsx({
																	[classes.selected]: item.id === selectedStoreAddress?.id,
																})}
															>
																{row}
															</tr>
														</CreateEditStoreAddress>
													) : (
														<tr
															onClick={handleSelectStoreAddress(item.storeAddressId)}
															className={clsx({
																[classes.selected]: item.id === selectedStoreAddress?.id,
																[classes.deactivated]: item.status === STORE_TYPE.DEACTIVATED,
															})}
														>
															{row}
														</tr>
													)}
												</React.Fragment>
											);
										})}
									</>
								)}
							</tbody>
						</table>
					</>
				)}
				{renderPagination()}
			</div>
			<div className={clsx(classes.sidebar, classes.flex, classes.justifyContentCenter, classes.alignItemsCenter)}>
				{selectedStoreAddress ? (
					<div className={classes.selectedStoreAddress}>
						<div className={classes.storeInfo}>
							<div className={clsx(classes.flex, classes.alignItemsCenter, classes.subtitleWrap, classes.justifyContentBetween)}>
								<div className={classes.subtitle}>{t('selectedAddressTitlte')}</div>
								<IconButton onClick={handleCloseSelectedStoreAddress} testId={TEST_IDS.STORE_ADDRESSES_PAGE_CLOSE_ITEM}>
									<CloseIcon className={classes.closeIcon} />
								</IconButton>
							</div>
							<div className={clsx(classes.flex)}>
								<PlaceIcon className={classes.placeIcon} />
								{selectedStoreAddress.address}
							</div>
						</div>
						<StoreListInfo
							store={selectedStoreAddress}
							refetchQueries={[
								{
									query: GET_STORE_ADRESS_HISTORY,
									fetchPolicy: 'network-only',
									variables: { storeAddressId: selectedStoreAddress.storeAddressId },
								},
							]}
						/>
					</div>
				) : (
					<div className={classes.noSelectedAddress}>
						<img src={noSelectedAddressSrc} alt="" />
						{t('noSelectedAddress')}
					</div>
				)}
			</div>
		</div>
	);
};

export default StoreAddresses;
