import type { IDebt, IDebtWithChildren } from 'interfaces/invoices';

const contractNameTo18nKeyDictionary = {
	'Договір про надання послуг': 'tooltips.warning',
	'Договір про надання маркетингово-рекламних послуг': 'tooltips.warning-marketing',
	'Договір про надання інформаційних послуг': 'tooltips.warning-info',
};

export const getDebtTooltipI18nKey = (contract: string) => {
	const key = contract as keyof typeof contractNameTo18nKeyDictionary;
	return contractNameTo18nKeyDictionary[key] || contractNameTo18nKeyDictionary['Договір про надання послуг'];
};

export const getDebtTooltipRootI18nKeys = (root: IDebtWithChildren) => {
	const childrenDebtsI18nKeys = root.children.map((debt: IDebt) => getDebtTooltipI18nKey(debt.contractName));
	const rootDebtI18nKey = getDebtTooltipI18nKey(root.contractName);
	const i18nKeyCandidates = [rootDebtI18nKey, ...childrenDebtsI18nKeys];

	const uniqueI18nKeys = new Set(i18nKeyCandidates);

	return Array.from(uniqueI18nKeys.entries());
};
