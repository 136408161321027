import React, { useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import clsx from 'helpers/clsx';
import { delay } from 'helpers/utis';
import { ReactComponent as CloseIcon } from 'static/images/close.svg';
import { ReactComponent as EyeIcon } from 'static/images/eye.svg';
import { ReactComponent as EyeCrossIcon } from 'static/images/eye-cross.svg';

import { IPropsInput } from './types';

import 'react-datepicker/dist/react-datepicker.css';
import classes from './FormControl.module.scss';
registerLocale('uk', uk);

const Input: React.ForwardRefRenderFunction<HTMLDivElement, IPropsInput> = (
	{
		rootClassName,
		controlClassName,
		onChange: onChangeProps,
		onClickIcon,
		onFocus,
		onBlur,
		Icon,
		clear,
		value: valueProps,
		disabled = false,
		positionIcon = 'left',
		placeholder,
		label,
		type,
		delayOnChange = 0,
		minLengthWhenCallOnChange,
		fullWidth,
		error,
		autoComplete,
		testId,
		subLabel,
		maxLength,
		minLength,
		min,
		max,
		pattern,
		setError,
		name,
		id,
	},
	refRoot,
) => {
	const getValue = (v: string | number | undefined) => {
		if (v === undefined || v === null) return '';
		return v;
	};

	const [value, setValue] = useState<string | number>(getValue(valueProps));
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const toggleShowPassword = () => {
		setShowPassword((state) => !state);
	};

	useEffect(() => {
		setValue(getValue(valueProps));
	}, [valueProps]);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		let newValue =
			type === 'number'
				? inputValue
						.replace(/[^\d,.]+/, '')
						.replace(',', '.')
						.replace(/\.+/, '.')
				: inputValue;
		if (pattern && !pattern.test(inputValue) && inputValue.length) {
			newValue = value as string;
			setError?.();
		}
		setValue(newValue);
		if (delayOnChange === 0) {
			onChangeProps?.(newValue);
		} else {
			if (!minLengthWhenCallOnChange || newValue.length >= minLengthWhenCallOnChange || !newValue.length) {
				delay('onChangeInput', () => onChangeProps?.(newValue), delayOnChange);
			}
		}
	};

	const onClear = () => {
		setValue('');
		onChangeProps?.('');
	};

	const getTypeInput = () => {
		if (type === 'number') return 'text';
		if (type !== 'password') return type;
		if (showPassword) return 'text';
		return 'password';
	};

	const getIcon = () => {
		if (type !== 'password') {
			return (
				Icon && <Icon className={clsx(classes.icon, { [classes.pointer]: !!onClickIcon, [classes.disabled_icon]: disabled })} onClick={onClickIcon} />
			);
		}
		if (showPassword) {
			return <EyeCrossIcon className={clsx(classes.icon, classes.pointer)} onClick={toggleShowPassword} />;
		}
		return <EyeIcon className={clsx(classes.icon, classes.pointer)} onClick={toggleShowPassword} />;
	};

	const resolvedName = name || id;
	const labelId = resolvedName ? `${resolvedName}::label` : undefined;

	return (
		<div
			ref={refRoot}
			className={clsx(classes.root, classes.input, rootClassName, {
				[classes.positionIconRight]: positionIcon === 'right' || type === 'password',
				[classes.fullWidth]: fullWidth,
			})}
		>
			{label && (
				<label htmlFor={resolvedName} id={labelId} className={classes.label}>
					{label}
					{subLabel && (
						<React.Fragment>
							<br />
							<span className={classes.subLabel}>{subLabel}</span>
						</React.Fragment>
					)}
				</label>
			)}
			<div className={classes.inner}>
				{getIcon()}
				{clear && value && positionIcon !== 'right' && (
					<CloseIcon className={clsx(classes.icon, classes.pointer, classes.icon_close)} onClick={onClear} />
				)}
				<input
					className={clsx(classes.control, classes.textField, controlClassName, {
						[classes.withIcon]: !!Icon,
						[classes.error]: error,
					})}
					name={resolvedName}
					id={resolvedName}
					onChange={onChange}
					value={value}
					disabled={disabled}
					placeholder={placeholder}
					type={getTypeInput()}
					autoComplete={autoComplete}
					data-testid={testId}
					onFocus={onFocus}
					onBlur={onBlur}
					maxLength={maxLength}
					minLength={minLength}
					max={max}
					min={min}
				/>
			</div>
			{error && <div className={classes.errorText}>{error}</div>}
		</div>
	);
};

export default React.forwardRef(Input);
