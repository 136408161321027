import { PARTNER_ROLES, Routes } from 'const';
import i18n from 'i18n';
import { CountryCodeEnum } from 'interfaces';
import { IReleaseAnnouncement } from 'interfaces/releaseAnnouncement';
import { RoleEnum } from 'interfaces/users';
import calculationOnDemand from 'static/images/calculationOnDemand.png';
import changeIBAN from 'static/images/changeIBAN.png';
import changesInPaymentDetails from 'static/images/changesInPaymentDetails.png';
import deliveryRadiusChanges from 'static/images/lassDeliveryRadiusChanges.png';
import newPartnerReports from 'static/images/newPartnerReports.png';
import paymentCalculationUA from 'static/images/paymentCalculationUA.png';
import changePaymentSchedule from 'static/images/paymentSchedule.png';
import paymentScheduleRU from 'static/images/paymentScheduleRU.png';
import paymentScheduleUA from 'static/images/paymentScheduleUA.png';
import submitEdits from 'static/images/submitEdits.png';
import videoUserUrlKZ from 'static/videos/kz-users.mp4';
import videoAccountsUrl from 'static/videos/release-announcementItems_accounts.mp4';
import videoDocsUrl from 'static/videos/release-announcementItems_vhasno_docs.mp4';
import videoUserUrlUA from 'static/videos/ua-users.mp4';

import { getCountry } from './jwt';
import localStorage from './localStorage';
import { hasRole } from './users';

const prefix = 'releaseAnnouncement';

export const setViewed = (it: IReleaseAnnouncement) => {
	localStorage.setItem(`${prefix}.${it.label}`, true);
};

export const isViewed = (it: IReleaseAnnouncement) => localStorage.getItem(`${prefix}.${it.label}`);

export const getReleaseAnnouncementItems = async (userRoles?: RoleEnum[]): Promise<IReleaseAnnouncement[]> => {
	await i18n.loadNamespaces(['releaseAnnouncement']);
	const country = getCountry();
	return [
		{
			id: '0',
			title: i18n.t('releaseAnnouncement:paymentCalculation.title'),
			content: i18n.t('releaseAnnouncement:changeIBAN.content'),
			image: changeIBAN,
			countries: [CountryCodeEnum.UA],
			label: 'changeIBAN',
			roles: [RoleEnum.CREDENTIALS_ADMIN, ...PARTNER_ROLES],
		},
		{
			id: '1',
			title: i18n.t('releaseAnnouncement:submitEdits.title'),
			content: i18n.t('releaseAnnouncement:submitEdits.content'),
			image: submitEdits,
			countries: [CountryCodeEnum.UA],
			label: 'submitEdits',
			roles: [RoleEnum.CREDENTIALS_ADMIN, ...PARTNER_ROLES],
		},
		{
			id: '2',
			title: i18n.t('releaseAnnouncement:calculationOnDemand.title'),
			content: i18n.t('releaseAnnouncement:calculationOnDemand.content'),
			image: calculationOnDemand,
			countries: [CountryCodeEnum.UA],
			label: 'calculationOnDemand',
			roles: [RoleEnum.CREDENTIALS_ADMIN, RoleEnum.CUSTOMER],
			expirationDate: new Date('2024-09-07'),
		},
		{
			id: '3',
			title: i18n.t('releaseAnnouncement:changePaymentSchedule.title'),
			content: i18n.t('releaseAnnouncement:changePaymentSchedule.content'),
			image: changePaymentSchedule,
			countries: [CountryCodeEnum.UA],
			label: 'changePaymentSchedule',
			roles: [RoleEnum.CREDENTIALS_ADMIN, ...PARTNER_ROLES],
		},
		{
			id: '4',
			title: i18n.t('releaseAnnouncement:paymentCalculation.title'),
			content: i18n.t('releaseAnnouncement:paymentCalculation.content'),
			image: paymentCalculationUA,
			countries: [CountryCodeEnum.UA],
			label: 'paymentCalculation',
			roles: [RoleEnum.CREDENTIALS_ADMIN, ...PARTNER_ROLES],
		},
		{
			id: '5',
			title: i18n.t('releaseAnnouncement:GMOContracts.title'),
			list: i18n.t('releaseAnnouncement:GMOContracts.list', { returnObjects: true }),
			countries: [CountryCodeEnum.UA],
			href: 'https://docs.google.com/presentation/d/1cDFi5_mG40F-KyW43dJuGUTS2ZcdlDIAyuNimvS4T2Y/edit#slide=id.g27c4ba2e5a8_0_23',
			label: 'GMOContracts',
			target: true,
			buttonText: i18n.t('releaseAnnouncement:GMOContracts.button'),
			roles: PARTNER_ROLES,
		},
		{
			id: '6',
			video: videoDocsUrl,
			title: i18n.t('releaseAnnouncement:vchasnoDocs.title'),
			list: i18n.t('releaseAnnouncement:vchasnoDocs.list', { returnObjects: true }),
			countries: [CountryCodeEnum.UA],
			href: Routes.DOCUMENTS,
			label: 'docsVhasno',
			roles: Object.values(RoleEnum).filter((role) => role !== RoleEnum.MENUTOOL && role !== RoleEnum.CUSTOMER),
		},
		{
			id: '7',
			video: country === CountryCodeEnum.UA ? videoUserUrlUA : videoUserUrlKZ,
			title: i18n.t('releaseAnnouncement:users.title'),
			list: i18n.t('releaseAnnouncement:users.list', { returnObjects: true }),
			countries: [CountryCodeEnum.UA, CountryCodeEnum.KZ],
			href: Routes.USERS,
			label: 'users',
			roles: [RoleEnum.STORE_ADMIN, RoleEnum.STORE_MANAGER],
		},
		{
			id: '8',
			video: videoAccountsUrl,
			title: i18n.t('releaseAnnouncement:accounts.title'),
			list: i18n.t('releaseAnnouncement:accounts.list', { returnObjects: true }),
			countries: [CountryCodeEnum.UA],
			href: Routes.ACCOUNTS,
			label: 'accounts',
			roles: [RoleEnum.CREDENTIALS_ADMIN, ...PARTNER_ROLES],
		},
		{
			id: '9',
			image: country === CountryCodeEnum.UA ? paymentScheduleUA : paymentScheduleRU,
			title: i18n.t('releaseAnnouncement:paymentSchedule.title'),
			list: i18n.t('releaseAnnouncement:paymentSchedule.list', { returnObjects: true }),
			countries: [CountryCodeEnum.UA, CountryCodeEnum.KZ, CountryCodeEnum.KG],
			href: Routes.PAYMENTSCHEDULE,
			label: 'paymentSchedule',
			roles: PARTNER_ROLES,
		},
		{
			id: '10',
			title: i18n.t('releaseAnnouncement:paymentCalculation.title'),
			content: i18n.t('releaseAnnouncement:changesInPaymentDetails.content'),
			image: changesInPaymentDetails,
			countries: [CountryCodeEnum.UA],
			label: 'changesInPaymentDetails',
			roles: PARTNER_ROLES,
		},
		{
			id: '11',
			title: i18n.t('releaseAnnouncement:deliveryRadiusChanges.title'),
			content: i18n.t('releaseAnnouncement:deliveryRadiusChanges.content'),
			image: deliveryRadiusChanges,
			countries: [CountryCodeEnum.UA],
			label: 'deliveryRadiusChanges',
			roles: [RoleEnum.CUSTOMER],
			expirationDate: new Date('2025-05-31'),
		},
		{
			id: '12',
			title: i18n.t('releaseAnnouncement:newPartnerReports.title'),
			content: i18n.t('releaseAnnouncement:newPartnerReports.content'),
			image: newPartnerReports,
			countries: [CountryCodeEnum.UA],
			label: 'newPartnerReports',
			roles: PARTNER_ROLES,
		},
	]
		.reverse()
		.filter((it: IReleaseAnnouncement) => {
			return (
				!isViewed(it) &&
				(!it.roles?.length || hasRole(userRoles, it.roles)) &&
				(!it.countries?.length || it.countries.includes(country as CountryCodeEnum)) &&
				(it?.expirationDate ? !(it.expirationDate.getTime() < Date.now()) : true)
			);
		});
};
