import { gql } from '@apollo/client';

export const GET_INVOICES = gql`
	query Invoices($page: Int, $pageSize: Int, $filter: InvoicePageFilter) {
		invoices(page: $page, pageSize: $pageSize, filter: $filter) {
			invoiceDtos {
				id
				date
				number
				companyName
				edrpou
				contractNumber
				totalAmount
				paidAmount
				paidDate
				purpose
				residualAmount
				status
				children {
					id
					date
					number
					companyName
					edrpou
					contractNumber
					totalAmount
					purpose
				}
			}
			total
			totalPages
		}
	}
`;

export const GET_PAYMENTS = gql`
	query Payments($page: Int, $pageSize: Int, $filter: PaymentPageFilter) {
		payments(page: $page, pageSize: $pageSize, filter: $filter) {
			paymentDtos {
				id
				date
				number
				companyName
				edrpou
				contractNumber
				invoiceNumber
				totalAmount
				purpose
			}
			total
			totalPages
		}
	}
`;

export const GET_DEBT_GLOVO = gql`
	query DebtGlovo($date: Date!, $edrpou: [String]) {
		debtGlovo(date: $date, edrpou: $edrpou) {
			id
			edrpou
			companyName
			contractNumber
			contractName
			expiredDebt30DaysAmount
			unexpiredDebtAmount
			storeAddressesIds
			expiredDebtAmount
			totalDebtAmount
			documentName
			documentDate
		}
	}
`;

export const GET_DEBT_COMPANIES = gql`
	query DebtCompanies($date: Date!, $edrpou: [String]) {
		debtCompanies(date: $date, edrpou: $edrpou) {
			id
			edrpou
			companyName
			contractNumber
			contractName
			expiredDebt30DaysAmount
			storeAddressesIds
			unexpiredDebtAmount
			expiredDebtAmount
			totalDebtAmount
			documentName
			documentDate
		}
	}
`;

export const GET_COMPANY_INFO = gql`
	query CompanyInfo($filter: AccountFilter) {
		companyInfo(filter: $filter) {
			taxId
			companyName
			contractInfo {
				value
				title
			}
		}
	}
`;

export const GET_DEBT_INFO = gql`
	query GetDeptForUser {
		getDeptForUser {
			expiredDebtAmount
			expiredDebt30DaysAmount
			totalDebtAmount
		}
	}
`;

export const GET_STORE_NAME_FILTER = gql`
	query CreditDebtStoreFilter($filterCase: CreditDebtFilterCase) {
		creditDebtStoreFilter(filterCase: $filterCase) {
			storeName
			taxId
		}
	}
`;

export const GET_COMPANY_NAME_FILTER = gql`
	query CreditDebtStoreFilter($filterCase: CreditDebtFilterCase) {
		creditDebtStoreFilter(filterCase: $filterCase) {
			companyName
			taxId
		}
	}
`;

export const GET_ADDRESSES_FILTER = gql`
	query CreditDebtStoreFilter($filterCase: CreditDebtFilterCase) {
		creditDebtStoreFilter(filterCase: $filterCase) {
			storeAddressId
			address
			taxId
		}
	}
`;
